// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeDash,
    placeCircle
} from '../../../common/edliy_utils-bernoulli';
const Boxes = {
  box1: function () {
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-6, 10, 10, -6],
  keepaspectratio: true, axis:false, ticks:{visible:false},
  grid:true, showCopyright:false, showNavigation:false,
  pan:{enabled:false}, zoom:{enabled:false}});
  brd1.suspendUpdate();
  brd1.options.board.minimizeReflow = 'none';
  brd1.options.text.highlight =false;
  brd1.options.text.fixed =true;
  brd1.options.text.highlight =false;
  brd1.options.text.cssStyle='fontFamily:Oswald',
  brd1.options.image.highlight =false;
  brd1.options.polygon.highlight =false;
  brd1.options.line.highlight =false;
  brd1.options.point.highlight =false;
  brd1.options.curve.highlight =false;
  brd1.options.slider.highlight =false;
  brd1.options.point.showInfoBox =false;
  brd1.options.image.layer =15;
  brd1.options.point.layer =16;
//brd1.options.layer['image'] =14;
//Title and tetxs
  makeResponsive(brd1);
  placeTitle(brd1, 'Conservation of Angular Momentum', '');
  //var title1 = brd1.create('text', [7, 14, "<b> Bernoulli's Principle <b>"],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/500.)}},);
  //brd1.options.layer['image'] =14;

  //Title and tetxs
//  var title = brd1.create('text', [0, 7, '<b> Conservation Of Angular Momentum <b>'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
  var subtitle = brd1.create('text', [2, 7.5, 'L = I_i &omega;_i =  I_f &omega;_f'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
  var subtitle1 = brd1.create('text', [2, 6.5, '1. Give the disc an initial spin. 2. Place the ball on the rim of the disc'],{display:'internal', visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
  var subtitle2 = brd1.create('text', [2, 6.5, 'Insert the values with two decimals'],{display:'internal', visible: false,fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);

  //Variables
  var angle = 0;
  var angleBall = 0;

  //Sliders
  var wo = placeSlider(brd1, 4, 5.5, -10, 0, 10, 2, '&nbsp;&omega;_i', '&nbsp; rad/s');
  wo.setAttribute({snapWidth:0.1});
  //brd1.create('slider',[[4,5],[6,5],[-10,0,10]], {snapToGrid: false ,face:'circle', size:4, strokeWidth: 2, name:'w_i [rad/s]', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
  var m_disk =placeSlider(brd1, 4, 4.5, 50, 300, 500, 2, '&nbsp;M_d_i_s_c', '&nbsp; kg');
  //brd1.create('slider',[[4,4.5],[6,4.5],[50,200,500]], {snapToGrid: false ,face:'circle', size:4, strokeWidth: 2, name:'M_d_i_s_k', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
  var m_ball = placeSlider(brd1, 4, 3.5, 50, 300, 500, 2, '&nbsp;m_b_a_l_l', '&nbsp; kg');
  //brd1.create('slider',[[4,3.5],[6,3.5],[10,300,500]], {snapToGrid: false ,face:'circle', size:4, strokeWidth: 2, name:'m_b_a_l_l', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
  var r_disk =placeSlider(brd1, 4, 2.5, 1, 4, 5, 2, '&nbsp;r_d_i_s_c', '&nbsp; m');
  //brd1.create('slider',[[4,2.5],[6,2.5],[1,4,5]], {snapToGrid: false ,face:'circle', size:4, strokeWidth: 2, name:'r_d_i_s_k', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
  var circ = placeCircle(brd1, [0, 0],[()=>r_disk.Value()-0.1, 0], 0, 'black');
  //Inertia
  var I_disk = function(){return 0.5*m_disk.Value()*Math.pow(r_disk.Value(), 2)};
  var I_ball = function(){return m_ball.Value()*Math.pow(r_disk.Value(), 2)}; //CHANGE r

  //w final
  function wf(){

      if(ballOverDisk == true){
          return I_disk()*wo.Value()/(I_disk()+ I_ball());
      }

      else{
          return wo.Value();
      }
  }


  //Tetxs
  var txt1 = brd1.create('text', [6, 1.5 ,  function(){return 'I_d_i_s_c = ' + I_disk().toFixed(2)+'&nbsp; kg-m^2'}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
  var txt2 = brd1.create('text', [6, 0.5,  function(){return 'I_b_a_l_l = ' + I_ball().toFixed(2)+'&nbsp; kg-m^2'}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);
  var txt3 = brd1.create('text', [6, -0.5,  function(){return '&omega;_f = ' + wf().toFixed(2)+'&nbsp; rad/s'}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);

  //Rotate de Disk around its center
  var p0 = brd1.create('point', [0,0], {visible: false, size:8, name:'offset', opacity:0.3, fixed: true});
  var p1 = brd1.create('point', [3,0], {visible: false, size:8, name:'rotate+scale', opacity:0.3, fixed: true});
  var im = brd1.create('image',['/assets/dvd.svg', [function(){return -r_disk.Value()}, function(){return -r_disk.Value()}], [function(){return 2*r_disk.Value()},function(){return 2*r_disk.Value()}]], {fixed: true, opacity:1});

  var tOff = brd1.create('transform', [function(){return p0.X()},function(){return p0.Y()}], {type:'translate'});
  tOff.bindTo(im);
  tOff.bindTo(p1);
  var tRot = brd1.create('transform', [function(){return Math.atan2(p1.Y()-p0.Y(),p1.X()-p0.X())}, p0], {type:'rotate'});
  tRot.bindTo(im);
  var tOffInv = brd1.create('transform', [function(){return -p0.X()},function(){return -p0.Y()}], {type:'translate'});
  var tScale = brd1.create('transform', [function(){return p1.Dist(p0)/3;},
                                        function(){return p1.Dist(p0)/3;}], {type:'scale'});
  tOffInv.bindTo(im); tScale.bindTo(im); tOff.bindTo(im);

  //Ball
  var ball = brd1.create('point', [6, -3], {size:20, name:'', opacity:1., attractors:[circ], attractorDistance:1,  snatchDistance:0.5});
  var ballOverDisk = false;
  var r_ball = function(){return Math.sqrt( Math.pow((ball.Y() ) ,2) + Math.pow( (ball.X() ), 2) ) };

  //Functions
  function rotate (){

      angle = angle + wf()/20;

      if(ballOverDisk == true){
          angle = angle + wf()/20;
      }

    var posx = 3*Math.cos(angle);
    var posy = 3*Math.sin(angle);

    p1.moveTo([posx, posy], 1);

    if(ballOverDisk == true){

        angleBall = angleBall + wf()/10;

        posx = r_ball()*Math.cos(angleBall);
        posy = r_ball()*Math.sin(angleBall);

        ball.moveTo([posx, posy], 1);

    }

  }

  function spinBall(){

    angleBall = Math.atan(ball.Y()/ball.X());

    if(ball.X() < 0){
        angleBall = angleBall + Math.PI;
    }

    if(r_ball() < r_disk.Value()){
        ballOverDisk = true;
    }
    else{
        ballOverDisk = false;
    }
  }

  function stopBall(){
    ballOverDisk = false;
  }

  setInterval(function(){ rotate(); }, 100);

  ball.on('up',spinBall);
  ball.on('down',stopBall);
  r_disk.on('down', function(){ stopBall(); ball.moveTo([6, -3], 10) } );


  //TEST YOURSELF

  //Images
  var tryMe = brd1.create('image', ['/assets/tap.svg', [7, -0.5], [0.5, 0.5]], {visible: false, fixed: true});
  tryMe.setLabel('Try')
  tryMe.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
  tryMe.on('over', function () {this.label.setAttribute({visible:true});});
  tryMe.on('out', function () {this.label.setAttribute({visible:false});});

  var test_mode = brd1.create('image', ['/assets/blue.svg', [6, 6.5], [1,1]], {visible: true, fixed: true});
  test_mode.setLabel('Test Yourself!')
  test_mode.label.setAttribute({visible:false, offset:[-10, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
  test_mode.on('over', function () {this.label.setAttribute({visible:true});});
  test_mode.on('out', function () {this.label.setAttribute({visible:false});});

  var learn_mode = brd1.create('image', ['/assets/red.svg', [6, 6.5], [1,1]], {visible: false, fixed: true});
  learn_mode.setLabel('Learning Mode')
  learn_mode.label.setAttribute({visible:false, offset:[-10, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
  learn_mode.on('over', function () {this.label.setAttribute({visible:true});});
  learn_mode.on('out', function () {this.label.setAttribute({visible:false});});

  var ok1 = brd1.create('image', ['/assets/check.svg', [7, 1.8], [0.5, 0.5]], {visible: false, fixed: true});
  var ok2 = brd1.create('image', ['/assets/check.svg', [7, 1], [0.5, 0.5]], {visible: false, fixed: true});
  var ok3 = brd1.create('image', ['/assets/check.svg', [7, 0.2], [0.5, 0.5]], {visible: false, fixed: true});

  var cross1 = brd1.create('image', ['/assets/cross.svg', [7, 1.8], [0.5, 0.5]], {visible: false, fixed: true});
  var cross2 = brd1.create('image', ['/assets/cross.svg', [7, 1], [0.5, 0.5]], {visible: false, fixed: true});
  var cross3 = brd1.create('image', ['/assets/cross.svg', [7, 0.2], [0.5, 0.5]], {visible: false, fixed: true});

  //Inputs
  var inputI_disk = brd1.create('input', [15, 0.5, '0', 'I_d_i_s_c'], {fixed: true, cssStyle: 'fontFamily:Oswald; width:10%; background-color:#C1C1C1;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}, fixed:true,parse:true});
  var inputI_ball = brd1.create('input', [15, -0.5, '0', 'I_b_a_l_l'], {fixed: true, cssStyle: 'fontFamily:Oswald; width:10%; background-color:#C1C1C1;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}, fixed:true,parse:true});
  var inputwf = brd1.create('input', [15, -1.5, '0', '&omega;_f'], {fixed: true, cssStyle: 'fontFamily:Oswald; width:10%; background-color:#C1C1C1;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}, fixed:true,parse:true});


  //Check the values
  function check(){

      ok1.setAttribute({visible: false});
      ok2.setAttribute({visible: false});
      ok3.setAttribute({visible: false});
      cross1.setAttribute({visible: false});
      cross2.setAttribute({visible: false});
      cross3.setAttribute({visible: false});


      if(inputI_disk.Value() == I_disk().toFixed(2)){
          ok1.setAttribute({visible: true});
      }
      else{
          cross1.setAttribute({visible: true});

      }

      if(inputI_ball.Value() == I_ball().toFixed(2)){
          ok2.setAttribute({visible: true});
      }
      else{
          cross2.setAttribute({visible: true});

      }

      if(inputwf.Value() == wf().toFixed(2)){
          ok3.setAttribute({visible: true});
      }
      else{
          cross3.setAttribute({visible: true});

      }


  }

  //Mouse click
  tryMe.on('down',check);

  test_mode.on('up', function(){learn_mode.setAttribute({visible: true});
                              test_mode.setAttribute({visible: false});
                              txt1.setAttribute({visible: false});
                              txt2.setAttribute({visible: false});
                              txt3.setAttribute({visible: false});
                              tryMe.setAttribute({visible: true});

                              inputI_disk.moveTo([5,2], 10);
                              inputI_ball.moveTo([5,1.25], 10);
                              inputwf.moveTo([5,0.5], 10);

                              subtitle2.setAttribute({visible: true});
                              subtitle1.setAttribute({visible: false});
   });
  learn_mode.on('down', function(){learn_mode.setAttribute({visible: false});
                                   test_mode.setAttribute({visible: true});
                                   ok1.setAttribute({visible: false});
                                   ok2.setAttribute({visible: false});
                                   ok3.setAttribute({visible: false});
                                   cross1.setAttribute({visible: false});
                                   cross2.setAttribute({visible: false});
                                   cross3.setAttribute({visible: false});

                                   tryMe.setAttribute({visible: false});
                                   txt1.setAttribute({visible: true});
                                   txt2.setAttribute({visible: true});
                                   txt3.setAttribute({visible: true});
                                   inputI_disk.moveTo([15,2], 10);
                                   inputI_ball.moveTo([15,1.25], 10);
                                   inputwf.moveTo([15,0.5], 10);

                                   subtitle2.setAttribute({visible: false});
                                   subtitle1.setAttribute({visible: true});

  });

  brd1.unsuspendUpdate();
    },
}
export default Boxes;
