<template>
  <div>
    <h3>Angular velocity </h3>
    <p>
      It is the rate of change of angular position of the particle with respect to time. It tells us
      how fast an object is rotating. It is a vector quantity and is denoted by \(\omega\). It SI unit is rad/sec.
      If a body rotates by Δ\(\theta\) angle in a time duration of Δ\(t\), its average angular velocity over the time period Δ\(t\) is given by
      $$\omega=\frac{\Delta \theta}{\Delta t}$$
      When Δt tends to zero, we get the instantaneous angular velocity of the body which is given by
      $$\omega=\lim_{Δt\to0}\frac{Δθ}{Δt}=\frac{dθ}{dt}$$
      Its direction is along the axis of rotation of the body, perpendicular to the plane of rotation. Direction of
      the angular velocity \(\omega\) is given using the right-hand rule. If the fingers of the right hand are
      curled in the direction of rotation, the direction in which the thumb of the right hand points out, is the
      direction of angular velocity. Relation between angular velocity \(\vec{\omega}\) and linear velocity \(\vec{v}\) of a particle or a body in vector
      form is given as,
      $$\vec{v}=\vec{\omega} \times \vec{r}$$
      where \(\vec{r}\) is the position vector of the particle.
      Linear velocity is the vector product of angular velocity and position vector of the particle in
      angular motion. However, in most of the cases the relation between angular velocity is given only in terms of
      their magnitude i.e., \(v=\omega r \).
      Note that for rotation of a body about a fixed axis, direction of angular velocity remains constant however, its
      magnitude may change.
    </p>
    <h3>Angular acceleration</h3>
    <p>
      Angular acceleration is the rate of change of angular velocity. It is denoted by \(\alpha\) and its SI unit is rad/s<sup>2</sup>. It is
      also a vector quantity and its direction is also directed perpendicular to the plane of rotation. We have
      read how to find the direction of angular velocity. Now, if the angular velocity of the particle or object
      in rotational motion is increasing, the direction of angular acceleration is same as that of angular
      velocity and if the angular velocity is decreasing, the direction of angular acceleration is in a direction
      opposite to the direction of angular velocity.
      Average angular acceleration is given by
      $$\alpha= \frac{Δ\omega}{Δt}$$
      where Δt is the change in time and Δ\(\omega\) is the change in angular velocity.
      <br><br>Instantaneous angular
      acceleration is given by
      $$\alpha=\lim_{Δt\to0}\frac{Δ\omega}{Δt}=\frac{d\omega}{dt}$$
    </p>

    <h3>Moment of Inertia </h3>
    <p>
      Moment of inertia of a body is a quantity that determines the body's resistance to change in its rotational
      motion. The role of moment of inertia in rotational motion is same as that of mass in linear motion. It
      determines the torque required for a specific angular acceleration about the axis of rotation.
      Moment of inertia depends
      upon the distribution of mass in the body about its rotational axis. The farther are the masses from the
      axis of rotation, more is the moment of inertia of the body.
    </p>
    <p>
      For a point mass, moment of inertia is given by
      $$I =mr^2$$
      where \(m\) is the mass of the particle, \(I\) is its moment of inertia and \(r\) is the perpendicular
      distance of the
      particle from the axis of rotation.
    </p>
    <h3>Moment of Inertia for a system of particles </h3>
    <p>
      For a system with n particles having masses
      \(m_1, m_2, ... ,m_n\), moment of inertia I of the system is given by
      $$I=\sum_{i=1}^{n} m_ir_i^{2}$$
      Where \(r_i\) is respective perpendicular distance of particle of mass \(m_i\) from the axis of rotation.
    </p>
    <h3>Moment of Inertia for a rigid body </h3>
    <p>
      For extended objects with continuous mass distribution, we consider infinitesimally small elements of mass
      \(dm\) and the summation in above formula is replaced by integration. Moment of inertia \(I\) of the system is given
      by
      $$I=\int r^{2}\,dm$$
    </p>
    <h3>Torque</h3>
    <p>
      Torque is the rotational analogue of linear force. It is responsible for rotating a body and bringing
      about an angular acceleration in the body.
      If a force \(F \) acts on a particle whose position vector is \(\vec{r}\) with respect to the axis or point
      of rotation , then the torque \(\tau\) acting on the particle is equal to the vector product of the force
      acting on the particle and its position vector i.e.,
      $$\vec{\tau}=\vec{r} \times \vec{F}$$
      Here \( \tau \) is the torque of the force applied. Torque is a vector quantity and its direction is
      determined according to the right-hand rule in a plane perpendicular to the force applied and position
      vector of the particle. Torque is denoted by \(\tau\) and its SI unit is Newton-meter.
    </p>
    <p>
      The magnitude of torque is given by
      $$\tau=r F \sin \theta $$
      where \(r\) is the magnitude of position vector of the particle, \(F\) is the magnitude of the force applied
      and \(\theta\) is the angle
      between the direction of the force applied and position vector of the particle.
      Therefore, the turning effect on an object due to a force applied not only depends on the magnitude and
      direction of the force applied but also on the point of application of the applied force.

      Angular acceleration produced in a body not only depends on the torque applied on it, but also
      on its moment of inertia. The relation between torque \(\tau\), angular acceleration\(\alpha\) and moment
      of inertia \(I\) of the body is given by
      $$\vec{\tau}=I\vec{\alpha}$$
    </p>
    <h3 ref="PlayGraph">
      MagicGraph | Conservation of Angular Momentum
    </h3>
    <h5> To Get Started</h5>
    <p>
      Shown is a dvd disc that can spin about point 'O'.
      The disc has a  mass \(M_{disc}\) and radius \(r_{disc}\).
      You will also see a ball (treated as a point mass) that has a mass \(m_{ball}\).
      Start with giving the disc an initial spin. The initial angular velocity of the disc is \(\omega_i\) &mdash; which can be changed.
      Then place the ball on the rim of the disc, and observe the new angular velocity of the combined system (\(\omega_f\)).
      Change the mass of the ball and note how angular velocity changes.
    </p>
    <v-responsive>
      <v-layout align-center justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>

<script>
import Boxes from './Boxes.js'

export default {
  name: 'BernoulliPrinciple',
  data () {
    return {
      v1: 1,
      P1: 1,
      rho: 1,
      v2: undefined,
      P2: undefined,
    }
  },
  created: function () {
    // Store mutations
    let title = 'Conservation of Angular Momentum';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: ' Statement of Bernoulli\'s Principle', img:'/assets/number-1.svg',action: () => this.goto('intro')},
      {title: 'Derivation of Bernoulli\'s Principle', img:'/assets/number-2.svg',action: () => this.goto('derivation')},
      {title: 'Continuity Equation', img:'/assets/number-3.svg',action: () => this.goto('continuity')},
      {title: 'Conditions for Applicability', img:'/assets/number-4.svg',action: () => this.goto('appl')},
      {title: 'PlayGraph: Fluid Flow in a Vertical Duct', img:'/assets/touch.svg',action: () => this.goto('PlayGraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule =true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();

  },
  updated () {
    // MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
  }
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
